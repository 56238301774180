
import { PrimaryButton } from '@fluentui/react';
import { IRootStateProps } from '../state/RootState';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = (state:IRootStateProps) => {

    return (
        <PrimaryButton onClick={state.RootState.auth.login}>Sign In</PrimaryButton>
    );
}
