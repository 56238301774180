import { Label, MessageBar, MessageBarType, TextField } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import Dialog, { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { observer } from "mobx-react";
import AddChartState from "../state/AddChartState";

/**
 * Renders a button which, when selected, will open a popup for login
 */

interface IAddChartDialogProps {
    state:AddChartState;
}

export const AddChartDialog = observer((state:IAddChartDialogProps) => {

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
      };
      
    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Add new chart',
        subText: 'Select the SharePoint List to render your chart.',
    };

    return (

        <Dialog 
            hidden={!state.state.addDialogVisible} 
            onDismiss={state.state.cancel} 
            dialogContentProps={dialogContentProps}
            modalProps={modelProps}>
            
            <>
                <Label>SharePoint List URL</Label>
                <TextField value={state.state.listURLToAdd} onChange={(_,newValue) => { state.state.setListURL(newValue || "") }} />

                {state.state.isUrlInMySite ? 
                    <MessageBar messageBarType={MessageBarType.severeWarning}>
                            Adding lists within My Sites is not permitted. Other users will not have access. Shared dashboards should only present data from lists
                            within Team Sites.
                    </MessageBar> : <></>}
                
                    {state.state.isSharingLink ? 
                    <MessageBar messageBarType={MessageBarType.severeWarning}>
                            Adding lists via Sharing Links is not permitted. Please open the list, and copy the list URL from the browser address bar.
                    </MessageBar> : <></>}
                
            </>

            <DialogFooter>
                <PrimaryButton disabled={state.state.isUrlInMySite || state.state.isSharingLink} text="Add chart" onClick={state.state.addChart} />
                <DefaultButton text="Cancel" onClick={state.state.cancel} />
            </DialogFooter>
        </Dialog>
    );
});