import * as React from "react";
import { IRootStateProps } from "./state/RootState";
import { PageRouter } from "./components/pages/PageRouter";

/**
 * Implementation of SSO Tab configuration page
 */
export const AppTeams: React.FunctionComponent<IRootStateProps> = (props:IRootStateProps) => {

    return (
		<PageRouter rootState={props.RootState}></PageRouter>
    );
};
