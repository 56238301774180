import { CommandBar, ICommandBarItemProps, MessageBar, Panel, Spinner } from '@fluentui/react';
import { observer } from 'mobx-react';
import ChartRenderer from '../../rendering/ChartRenderer';
import { IRootStateProps } from '../../state/RootState';
import { ConfigurationPane } from '../ConfigurationPane';
import { CanvasRenderer } from '../../rendering/CanvasRenderer';
import { AddChartDialog } from '../AddChartDialog';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const DashboardPage = observer((state: IRootStateProps) => {

	if (state.RootState.dashboard.loadingId) {
		return <div>
			<Spinner label="Loading dashboard..." />
		</div>;
	} else {

		return (
			<div>
				{getCommandBar(state)}
				<AddChartDialog state={state.RootState.dashboard.addChartState}></AddChartDialog>
				{getCanvas(state)}
				<Panel
					isLightDismiss={true}
					isBlocking={false}
					headerText="Configure chart"
					isOpen={!!state.RootState.dashboard.selectedChart}
					onDismiss={() => { state.RootState.dashboard.setSelectedChart(null) }}
					// You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
					closeButtonAriaLabel="Close" >
					<ConfigurationPane state={state.RootState.dashboard.editChartState}></ConfigurationPane>
				</Panel>
			</div>
		);
	}
});

const getCanvas = (state:IRootStateProps) => {

	if(state.RootState.dashboard.charts.length === 0) {

		if(state.RootState.readonly) {
			return <MessageBar>This dashboard is empty.</MessageBar>;
		} else {
			return <MessageBar>This dashboard is empty. Click 'Insert chart' to add your first chart.</MessageBar>;
		}
	} else {
		return <CanvasRenderer state={state.RootState.dashboard} />;
	}
}

const getCommandBar = (state:IRootStateProps) => {
	
	let items = getItems(state);
	
	if(items.length > 0) {

		return <CommandBar
			items={items}
			ariaLabel="Dashboard actions"
		/>
	} else {
		return <></>;
	}
};


const getItems = (state:IRootStateProps): ICommandBarItemProps[] => {

	let result = [];

	if(state.RootState.canNavigateBack && state.RootState.isEmbeddedOrMobile) {
		result.push({
				key: 'back',
				text: 'Back to dashboards',
				iconProps: { iconName: 'Back' },
				onClick: () => {
					state.RootState.setPage("viewerlist")
				},
			});
	}

	if(state.RootState.readonly) {

		//If it's readonly and we're in the Personal tab, then allow editing
		if(state.RootState.canNavigateBack && state.RootState.isEmbeddedOrMobile) {
			result.push({
				key: 'newItem',
				text: 'Launch editor',
				iconProps: { iconName: 'NavigateExternalInline' },
				onClick: () => {window.open("#designer/" + state.RootState.dashboard.dashboard?.id, "_blank")}
			  });
			  
			  result.push({
				key: 'refresh',
				text: 'Refresh',
				iconProps: { iconName: 'Refresh' },
				onClick: () => { window.location.reload(); }
			  });
		}

	} else {

		result.push(
			{
				key: 'newChart',
				text: 'Insert chart',
				iconProps: { iconName: 'BarChartVertical' },
				onClick: () => { state.RootState.dashboard.addChartState.startAddChart(); },
			});
		
		if(state.RootState.dashboard.selectedChart !== null) {

			result.push(
				{
					key: 'edit',
					text: 'Edit chart',
					iconProps: { iconName: 'BarChartVerticalEdit' },
					onClick: () => {
						state.RootState.dashboard.removeChart(state.RootState.dashboard.selectedChart as ChartRenderer);
					},
				});


			result.push(
				{
					key: 'delete',
					text: 'Remove chart',
					iconProps: { iconName: 'Delete' },
					onClick: () => {
						state.RootState.dashboard.removeChart(state.RootState.dashboard.selectedChart as ChartRenderer);
					},
				});
		}
	}


	return result;
};

