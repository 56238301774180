import { makeAutoObservable } from "mobx";
import API from "../API";
import { Dashboard } from "./DashboardState";

export default class DashboardsListState {
    
    public dashboards: Dashboard[] = [];
    public loadingDashboards:boolean = false;

    private getToken:() => Promise<string>;

    constructor(getToken:() => Promise<string>) {
        makeAutoObservable(this);
        
        this.getToken = getToken;
    }
    
    setDashboards = (dashboards:Dashboard[]):void => {
        this.loadingDashboards = false;
        this.dashboards = dashboards;
    }

    
    loadDashboards = () => {
        this.dashboards = [];
        this.loadingDashboards = true;
        this.getToken().then((token) => {
            if(token) {
                new API(token).getDashboards().then(this.setDashboards);
            } else {
                console.log("Log in before attempting to load dashboards");
            }
        });

    }

}