import { DefaultButton } from "@fluentui/react";
import { IRootStateProps } from "../state/RootState";

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = (state:IRootStateProps) => {

    return (
        <DefaultButton onClick={state.RootState.auth.logout}>Sign Out</DefaultButton>
    );
}