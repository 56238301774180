import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { RootState } from './state/RootState';
import { MSALAuth } from './state/authentication/MSALAuth';
import { TeamsSSOAuth } from './state/authentication/TeamsSSOAuth';
import { AppTeams } from './AppTeams';
import * as microsoftTeams from "@microsoft/teams-js";
import SubscriptionState from './state/SubscriptionState';
import { StandaloneSaasActivation } from './components/StandaloneSaasActivation';

initializeIcons();

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

//If we're just activating the saas token, it doesn't require a login or anything, so just show that component
let saasToken = RootState.getUrlParameter('token');
if(saasToken) {
  var subState = new SubscriptionState();
  subState.startConfirmingPaymentToken(saasToken);
  ReactDOM.render(
    <React.StrictMode>
      <StandaloneSaasActivation subscription={subState}></StandaloneSaasActivation>
    </React.StrictMode>, 
  document.getElementById('root'));

} else {
    
  let inPopup:boolean = false;
  let routing = RootState.getPageFromHash();
  let isTeams:boolean = routing.page === 'configuretab' || routing.page === 'viewer' || routing.page === 'viewerlist';

  if(routing.page === 'authComplete' || (RootState.isOAuthRedirect() && !window.menubar.visible /* Hacky McHackface to detect if it's not in a popup window */)) {
    inPopup = true;

    let root = document.getElementById('root');
    if(root) {
      root.innerHTML = "Login complete. You may close this window.";
    }

    microsoftTeams.app.initialize().then(() => {
      //Close the dialog?
      microsoftTeams.authentication.notifySuccess("ConsentComplete");
    });
  } else if(routing.page === 'authStart') {
    inPopup = true;

    microsoftTeams.app.initialize().then(async () => {
      let ctx = await microsoftTeams.app.getContext();
      let spDomain = ctx.sharePointSite?.teamSiteDomain;
      var requestObj = {
        scopes: ["https://graph.microsoft.com/User.Read", "https://"+ spDomain +"/AllSites.Read"],
        prompt: "select_account"
      };

      const clientApp = new PublicClientApplication({
        auth: {
          clientId: msalConfig.auth.clientId,
          redirectUri: msalConfig.auth.redirectUri
        }
      });

      clientApp.handleRedirectPromise().then(() => {

        //const accounts = clientApp.getAllAccounts();
        clientApp.loginRedirect(requestObj);
      });
    });

  } else if(isTeams) {

    var teamsAuth = new TeamsSSOAuth();
    var rootState = new RootState(teamsAuth);
    rootState.setInitialPageFromHash();

      if(routing.page !== 'personalTab') {
      teamsAuth.contextLoad.then(ctx => {
          //This only needs to be done when in a teams context.
          //This is because in a teams context, we're using a Teams token which doesn't given permission to SharePoint. 
          //We have to check if that's been granted, and if not, request it.
          
          rootState.spAuth.setSPAuthContext(ctx.sharePointSite?.teamSiteUrl || '', ctx.user?.tenant?.id || '');
          rootState.spAuth.startCheckSPAuth();
      });

    }

    ReactDOM.render(
      <React.StrictMode>
        <AppTeams RootState={rootState} />
      </React.StrictMode>, 
    document.getElementById('root'));

  } else {

    let useRedirectLoginFlow: boolean = true; //Set to false when in Teams or another embedded/iframe situation (not important when using SSO)
    var msalAuth = new MSALAuth(useRedirectLoginFlow);
    const msalInstance = new PublicClientApplication(msalConfig);

    if(!useRedirectLoginFlow) {
      //If this window is the popup doing a login redirect, then don't load react.
      const contentHash = window.location.hash;
      if(contentHash && contentHash.startsWith("#code=")) {
        let root = document.getElementById('root');
        if(root) {

          root.innerHTML = "Completing login...";
          inPopup = true;
        }
      }
    }

    if(!inPopup) {
      let rootState = new RootState(msalAuth);

      ReactDOM.render(
        <React.StrictMode>
          <MsalProvider instance={msalInstance}>
              <App RootState={rootState} />
          </MsalProvider>
        </React.StrictMode>, 
      document.getElementById('root'));
    }
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


