import { IRootStateProps } from "../../state/RootState";
import { Text } from '@fluentui/react/lib/Text';
import { MessageBar, Spinner } from "@fluentui/react";
import { observer } from "mobx-react";
import { SignOutButton } from "../SignOutButton";
import { SubscriptionInfo } from "../SubscriptionInfo";

export const AccountInfo = observer((state: IRootStateProps) => {

    if(state.RootState.account.loading) {
        return <>
            <Spinner label="Loading account info..." ariaLive="assertive" labelPosition="top" />
        </>;
    }

    return (
        <>
        
            {!state.RootState.account.message || <MessageBar>{state.RootState.account.message}</MessageBar>}

            <Text variant={'xLarge'}>Account</Text>

            <SubscriptionInfo state={state.RootState.account.subscriptionState}></SubscriptionInfo>

            
            <div>Thanks for using ListBurst for Teams. If you have any comments or questions, please <a href="contact@365automate.com">email us</a>.</div>

            <SignOutButton RootState={state.RootState} />
			  
        </>
    );
});
