import { makeAutoObservable } from "mobx";
import ChartRenderer from "../rendering/ChartRenderer";
import DashboardState from "./DashboardState";

export default class AddChartState {
    
	public addDialogVisible:boolean = false; 
	public listURLToAdd:string = "";

	public isUrlInMySite:boolean = false;
	public isSharingLink:boolean = false;

	private getToken: () => Promise<string>;

	private dashboardState:DashboardState
    
	constructor(getToken: () => Promise<string>, dashboardState:DashboardState) {
		makeAutoObservable(this);

		this.getToken = getToken;
		this.dashboardState = dashboardState;
	}

	startAddChart = () => {
		this.addDialogVisible = true;
	}

	cancel = () => {
		this.addDialogVisible = false;
		this.listURLToAdd = "";
	}

	addChart = () => {
		
		let chart = new ChartRenderer(null, this.getToken);
		chart.listUrl = this.listURLToAdd;
		chart.x = 0;
		chart.y = 0;
		chart.width = 4;
		chart.height = 3;
		chart.type = "pie";
		chart.title = "";
		chart.id = Math.floor((Math.random()) * 0x1000000000000).toString(16); //Generate a unique ID

		//Notify the dashboard that the chart was added
		this.dashboardState.addChart(chart);

		//Notify the dashboard when the chart is selected
		chart.setOnSelect(() => {
			this.dashboardState.setSelectedChart(chart);
		});

		//make it selected
		this.dashboardState.setSelectedChart(chart);

		this.listURLToAdd = "";
		this.addDialogVisible = false;
	}

	setListURL = (listURL: string) => {
        this.listURLToAdd = listURL;

		this.isUrlInMySite = (listURL || '').indexOf("-my.sharepoint.com") > 0;
		this.isSharingLink = (listURL || '').indexOf(":l:") > 0;
    }


}