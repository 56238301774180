import { Label, Spinner, TextField } from "@fluentui/react";
import { DefaultButton, PrimaryButton } from "@fluentui/react/lib/Button";
import Dialog, { DialogFooter, DialogType } from "@fluentui/react/lib/Dialog";
import { observer } from "mobx-react";
import AddDashboardState from "../state/AddDashboardState";

/**
 * Renders a button which, when selected, will open a popup for login
 */

interface IAddDashboardDialogProps {
    state:AddDashboardState;
}

export const AddDashboardDialog = observer((state:IAddDashboardDialogProps) => {

    const modelProps = {
        isBlocking: true,
        topOffsetFixed: true,
      };
      
    const dialogContentProps = {
        type: DialogType.largeHeader,
        title: 'Add new dashboard',
        subText: 'Type the name of your new dashboard.',
    };

    return (

        <Dialog 
            hidden={!state.state.addDialogVisible} 
            onDismiss={state.state.cancel} 
            dialogContentProps={dialogContentProps}
            modalProps={modelProps}>
            
            <>
                <Label>Dashboard title</Label>
                <TextField value={state.state.titleToAdd} onChange={(_,newValue) => { state.state.setTitle(newValue || "") }} />
                
                {state.state.saveInProgress ? <Spinner>Creating...</Spinner> : <></> }
            </>


            <DialogFooter>
                <PrimaryButton text="Add dashboard" onClick={state.state.addDashboard} disabled={state.state.saveInProgress} />
                <DefaultButton text="Cancel" onClick={state.state.cancel} disabled={state.state.saveInProgress} />
            </DialogFooter>
        </Dialog>
    );
});