import { makeAutoObservable } from "mobx";
import API, { IPreexistingSubscription, ISubscription } from "../API";

export default class SubscriptionState {

	public subscription:ISubscription|null = null;
	public preExistingSubscription:IPreexistingSubscription|null = null;
    
	public appSrcToken:string = "";
	public confirmingPaymentToken:boolean = false;
	public confirmedPaymentToken:boolean = false;

	constructor() {
		makeAutoObservable(this);
	}

	public startConfirmingPaymentToken(token:string) {
		//Make requests to appsrc
		this.appSrcToken = token;

		if(!this.confirmingPaymentToken) {
			this.confirmingPaymentToken = true;

			new API("").processPaymentToken(this.appSrcToken).then((subState:ISubscription|IPreexistingSubscription) => {
				
				if((subState as IPreexistingSubscription).preexistingSubscriptionId)
				{
					this.preExistingSubscriptionConfirmation(subState as IPreexistingSubscription);
				}
				else
				{
					this.completeTokenConfirmation(subState as ISubscription);
				}
			});
		}
	}

	public preExistingSubscriptionConfirmation(existing:IPreexistingSubscription) {
		this.preExistingSubscription = existing;
		this.subscription = null;
		this.confirmedPaymentToken = true;
		this.confirmingPaymentToken = false;
	}

	public completeTokenConfirmation(subscription:ISubscription) {
		this.preExistingSubscription = null;
		this.subscription = subscription;
		this.confirmedPaymentToken = true;
		this.confirmingPaymentToken = false;
	}

	public setSubscription(subscription:ISubscription)
	{
		this.subscription = subscription;
	}
}
