import { GridStack, GridStackWidget } from "gridstack";
import API, { ISeries } from "../API";
import EditChartState, { IChartProperties } from "../state/EditChartState";

export enum SelectionState {
	Neutral, //Normal - nothing is selected
	Selected, //This chart is selected/highlighted
	Unselected //Another chart is highlighted and this one should be unhighlighted
}

export default class ChartRenderer implements IChartProperties {

	public listUrl: string = "";

	public id:string = "";
	public title:string = "";
	public x:number = 0;
	public y:number = 0;
	public width:number = 0;
	public height:number = 0;

	private grid:GridStack|null = null;
	private domElement:Element|null = null;
	private node:GridStackWidget|null = null;
	private data:google.visualization.DataTable|null = null;

	//IChartProperties implementation
	public numericFieldInternalName: string = "";
	public categoryFieldInternalName: string = "";
	public aggregationType: string = "";
	public type:string = "";

	private error:string = "";

	private onSelect: () => void = () => {};

	//Records whether this chart has already been added to the grid.
	//This allows the chart to be added to the grid after the grid is loaded, if the chart is constructed first.
	private addedToGrid:boolean = false;

	private getToken: () => Promise<string>;

	constructor(initValues:IChartProperties|null, getToken: () => Promise<string>) {
		this.getToken = getToken;
		if(initValues) {
			EditChartState.copyProperties(initValues, this);
		}
	}

	setOnSelect(onSelect: () => void) {
		this.onSelect = onSelect;
	}

	setIsSelected(selectionState:SelectionState) {
		let elem = document.getElementById(`${this.id}-outer`);
		if(elem) {

			elem.classList.remove('unselected');
			elem.classList.remove('selected');

			if(selectionState === SelectionState.Selected) {
				elem.classList.add('selected');
			} else if(selectionState === SelectionState.Unselected) {
				elem.classList.add('unselected');
			}
		}
	}

	//Removes it from the grid
	remove() {
		let elem = document.getElementById(`${this.id}-outer`);
		if(elem && elem.parentElement) {
			this.grid?.removeWidget(elem.parentElement);
		}
	}

	public addToGrid(grid:GridStack) : void {

		if(this.addedToGrid) {
			//If this chart has already been added to the grid, then no need to continue.
			return;
		}

		if((grid as any) instanceof GridStack) {
			this.grid = grid;
			this.addedToGrid = true;
		} else {
			console.log("grid not supplied: " + grid);
			return;
		}

		this.node = {
			x: this.x,
			y: this.y,
			w: this.width,
			h: this.height,
			id: this.id,
			content: "Loading...",
			minW: 3,
			minH: 2
		  };
	
		let innerId = `${this.node.id}-inner`;
		let outerId = `${this.node.id}-outer`;

		let indentStyles = '';//'margin-top:-10px;margin-left:-55px';

		let content = this.isConfigured() ? "<img src='loading.gif'>" : 'Please complete chart configuration.';

		let widgetHtml = `<div class="grid-stack-item">
							<div class="grid-stack-item-content" style='overflow-y:hidden;' id='${outerId}'>
								<div id='${innerId}' style='${indentStyles}'>${content}</div>
							</div>
						</div>`;
	
		grid.addWidget(widgetHtml, this.node);
		this.domElement = document.getElementById(innerId) as Element;

		if(this.data) {
			this.draw();
		} else {
			this.loadSeries();
		}

		document.getElementById(outerId)?.addEventListener('click', () => {
			this.onSelect();
		})
	}

	private isConfigured() {
		return this.aggregationType && this.numericFieldInternalName && this.categoryFieldInternalName && this.type;
	}



	public setNode(node:GridStackWidget) {
		this.node = node;
	}

	public loadSeries() {
		
		if(this.listUrl && this.aggregationType && this.categoryFieldInternalName && this.numericFieldInternalName) {
			this.getToken().then((token) => {
				if (token) {
					new API(token).getSeries(this.listUrl, this.numericFieldInternalName, this.categoryFieldInternalName, this.aggregationType).then(series => {
						console.log("loaded series" + series);

						if(!series) {
							this.error = "Unable to load data. You may not have access.";
							this.data = null;
						} else if(series.launchAuthenticationURL) {
							this.error = "Permission required to access chart data."
						} else {
							this.error = "";
							this.data = this.convertSeriesToDataTable(series.result);
						}

						this.draw();
					});
				}
			});
		}

	}

	convertSeriesToDataTable(series: ISeries): google.visualization.DataTable {
		var data = new google.visualization.DataTable();
		data.addColumn('string', series.groupedByField);
		data.addColumn('number', series.aggregation);
		
		let rows = series.points.map(p => [p.category, p.value]);
		data.addRows(rows);

		return data;
	}

	public draw() {

		google.charts.setOnLoadCallback(() => { this.drawInternal() });
	}

	private drawInternal() {

		if(this.node && this.domElement && this.grid) {

			if(this.error) {
				this.domElement.innerHTML = `<div>${this.error}</div>`;
			} else if(this.data) {

				console.log('re-render. numericFieldInternalName=' + this.numericFieldInternalName);

				let width = (Number(this.node.w) * (this.grid.cellWidth() || 0));
				let height = (Number(this.node.h) * (this.grid.getCellHeight() || 0));

				//Don't allow the width to go over the total grid width. This can happen when it switches to one-column mode.
				if(!this.grid.opts.disableOneColumnMode && (this.grid.opts.minWidth || 0) > this.grid.el.clientWidth) {
					width = this.grid.cellWidth();
					height = this.grid.getCellHeight();
				}

				let standardMargin = 10;
				let titleHeight = this.title ? 20 : 0;

				// This is the chart area with no room for any axes labels. Each chart needs to be adjusted separately.
				// There is a 10px margin.
				let chartArea = {
					top:standardMargin + titleHeight, 
					left:standardMargin, 
					'width': width - (standardMargin*2), 
					'height': height - (standardMargin*2) - titleHeight
				};

				// Instantiate and draw our chart, passing in some options.
				if(this.type === "pie") {
					var pie = new google.visualization.PieChart(this.domElement);
					pie.draw(this.data, { title:this.title, width: width, height: height, chartArea:chartArea});
				} else if(this.type === "column") {
					var column = new google.visualization.ColumnChart(this.domElement);
					
					//Space for the text labels at the bottom
					let labelsSize = 30;
					chartArea.height -= labelsSize; 

					//Space for the number labels at the side
					let numberLabelsSize = 60;
					chartArea.left += numberLabelsSize;
					chartArea.width -= numberLabelsSize;

					column.draw(this.data, { title:this.title, width: width, height: height, legend:'none', chartArea:chartArea });
				} else if(this.type === "bar") {
					var bar = new google.visualization.BarChart(this.domElement);
					let labelsSize = 60;
					chartArea.left += labelsSize;
					chartArea.width -= labelsSize;

					//Leave room at the bottom for the labels
					let numberLabelsSize = 30;
					chartArea.height -= numberLabelsSize;

					bar.draw(this.data, {  title:this.title, width: width, height: height, legend:'none', chartArea:chartArea });
				} else if(this.type === "table") {
					var table = new google.visualization.Table(this.domElement);
					var tableOptions : google.visualization.TableOptions = {
						width: '100%',
						height:'100%'
					};
					table.draw(this.data, tableOptions);
				}
			}
		}
	}
}