import { makeAutoObservable } from "mobx";
import API from "../API";
import { Dashboard } from "./DashboardState";
import { RootState } from "./RootState";

export default class AddDashboardState {
    
	public saveInProgress: boolean = false;
	public addDialogVisible:boolean = false; 
	public titleToAdd:string = "";

	private getToken: () => Promise<string>;
	private state:RootState;

	constructor(getToken: () => Promise<string>, state:RootState) {
		makeAutoObservable(this);

		this.getToken = getToken;
		this.state = state;
	}

	startAddDashboard = () => {
		this.addDialogVisible = true;
	}

	cancel = () => {
		this.addDialogVisible = false;
		this.titleToAdd = "";
	}

	addDashboard = ():void => {
        
		let dashboard = new Dashboard();
		dashboard.title = this.titleToAdd;
		dashboard.published = true;

		this.saveInProgress = true;

        this.getToken().then((token) => {
            if(token) {
                new API(token).saveDashboard(dashboard).then(newId => {
                    this.addDashboardComplete(dashboard, newId);
                });
            } else {
                console.log("Log in before attempting to load dashboards");
            }
        });
    }

    addDashboardComplete = (dashboard:Dashboard, newId:string) => {
        this.saveInProgress = false;
		this.addDialogVisible = false;

        //Select the dashboard?
        dashboard.id = newId;
        this.state.dashboardsState.dashboards.push(dashboard);

		this.state.selectDashboard(dashboard);

    }


	setTitle = (title: string) => {
        this.titleToAdd = title;
    }


}