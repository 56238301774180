import { makeAutoObservable } from "mobx";
import API from "../API";
import ChartRenderer, { SelectionState } from "../rendering/ChartRenderer";
import AddChartState from "./AddChartState";
import { GridStack } from "gridstack";
import EditChartState, { IChartProperties } from "./EditChartState";
import { RootState } from "./RootState";

export class Dashboard {
	public id: string = "";
	public title: string = "";
	public published: boolean = true;
}

export default class DashboardState {

	public dashboard: Dashboard | null = null;

	public charts: ChartRenderer[] = [];

	public selectedChart: ChartRenderer | null = null;

	//Id of the dashboard being loaded
	public loadingId: string | null = null;

	public addChartState:AddChartState;

	public editChartState:EditChartState;

	public grid:GridStack|null = null;

	private getToken: () => Promise<string>;

	public readOnly:boolean = false;

	private rootState:RootState;

	constructor(getToken: () => Promise<string>, rootState:RootState) {
		this.getToken = getToken;
		makeAutoObservable(this);
		
		this.rootState = rootState;
		this.addChartState = new AddChartState(getToken, this);
		this.editChartState = new EditChartState(getToken, this);
	}

	selectDashboard = (dashboard: Dashboard): void => {
		if (this.loadingId !== dashboard.id || this.dashboard == null) {
			this.dashboard = dashboard;
			this.loadCharts(dashboard.id);

			this.rootState.setAppTitle(dashboard.title, '#viewer/' + dashboard.id);
		}
	}

	//Supply dashboard ID and optionally the dashboard object which is null if navigating directly to this dashboard via URL.
	loadDashboardById = (id: string, readOnly:boolean): void => {

		this.readOnly = readOnly;

		if (this.loadingId !== id) {
			this.loadingId = id;
			this.getToken().then((token) => {
				new API(token).getDashboard(id).then(dashboard => {
					this.selectDashboard(dashboard);
				});
			});
		}
	}

	private loadCharts = (dashboardId: string): void => {
		this.loadingId = dashboardId;
		this.getToken().then((token) => {
			if (token) {
				new API(token).getCharts(dashboardId).then(this.setCharts.bind(this));
			} else {
				console.log("Log in before attempting to load dashboard");
			}
		});
	}

	setCharts = (charts: IChartProperties[]): void => {

		charts.forEach(chart => {
			this.addChart(new ChartRenderer(chart, this.getToken));
		});

		console.log("charts set");
		
		this.loadingId = null;
	}

	addChart = (chart:ChartRenderer): void => {
		this.charts.push(chart);
		
		//Notify the dashboard when the chart is selected
		chart.setOnSelect(() => {
			if(chart !== this.selectedChart) {
				this.setSelectedChart(chart);
			}
		});

		if(this.grid) {
			chart.addToGrid(this.grid);
		}
	}

	removeChart(chartToRemove: ChartRenderer) {
		if(chartToRemove === this.selectedChart) {
			this.setSelectedChart(null);
		}

		let index = this.charts.indexOf(chartToRemove);
		if(index >= 0) {
			this.charts.splice(index, 1);
		}

		//Remove it from the grid
		chartToRemove.remove();

		//Update server
		this.getToken().then((token) => {
			if (token) {
				new API(token).deleteChart(chartToRemove.id);
			}
		});
	}

	setSelectedChart(chart: ChartRenderer|null) {

		//You can't select a chart when in readonly mode (selection is only used for showing property pane)
		if(this.readOnly)
			return;

		this.selectedChart = chart;

		this.charts.forEach(c => {

			let state:SelectionState;

			if(c === chart) {
				state = SelectionState.Selected;
			} else if (chart === null) {
				state = SelectionState.Neutral;
			} else {
				state = SelectionState.Unselected;
			}

			c.setIsSelected(state);
		});

		//Load the configuration pane for the selected chart
		this.editChartState.loadSelectedChart();
	}

	saveChartCoordinates(chart: ChartRenderer) {
		//Get the fields
			this.getToken().then(token => {
	
				new API(token).saveChart(new ChartRenderer(chart, this.getToken), this.dashboard?.id || "", true);
			});
	  }
}