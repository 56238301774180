import { makeAutoObservable, } from "mobx"
import DashboardsListState from "./DashboardsListState";
import DashboardState, { Dashboard } from "./DashboardState";
import { IAuth } from "./authentication/IAuth";
import { TeamsSSOAuth } from "./authentication/TeamsSSOAuth";
import AddDashboardState from "./AddDashboardState";
import AccountState from "./AccountState";
import { msalConfig } from "../authConfig";
import { SPAuth } from "./authentication/SPAuth";

export interface IRootStateProps {
    RootState:RootState;
  }

  export interface RoutingInfo {
      page:string;
      argument:string|undefined;
  }
  
export class RootState {

    public appTitle:string = "";
    public appTitleLink:string = "";

    //Stuff to do with login
    public name:string = "";
    public page:string = "";

    public spAuth:SPAuth;

    public dashboardsState:DashboardsListState;
    public addDashboardState: AddDashboardState;
    public account:AccountState;

    public dashboard:DashboardState;

    //True if we're just viewing a dashboard or some other page where we don't want chrome.
    public hidePageNavigation:boolean = false;
    public readonly:boolean = false;

    //True if the initially loaded page was navigated to directly, rather than from within the app.
    //If a dashboard is loaded, and this is true, then the back button shouldn't be shown.
    //THIS DOES NOT WORK ON NATIVE TEAMS/IOS
    public isEmbeddedOrMobile:boolean = window.self !== window.top || /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    public canNavigateBack:boolean = document.referrer.replace(/\/$/, "") === msalConfig.auth.redirectUri;

    public auth:IAuth;

    constructor(auth:IAuth) {
        makeAutoObservable(this);

        this.auth = auth;

        this.spAuth = new SPAuth(this.auth.getToken);
        this.dashboardsState = new DashboardsListState(this.auth.getToken);
        this.dashboard = new DashboardState(this.auth.getToken, this);
        this.addDashboardState = new AddDashboardState(this.auth.getToken, this);
        this.account = new AccountState(this.auth.getToken);
    }

    setAppTitle(title:string, link:string) : void {
        console.log("Setting title to " + title);
        this.appTitle = title;
        this.appTitleLink = link;
    }

    selectDashboard(dashboard: Dashboard) {

        if(this.page === 'configuretab') {
            (this.auth as TeamsSSOAuth).selectDashboard(dashboard);
        } else if(this.page === 'viewerlist') {
            window.location.hash = "viewer/" + dashboard.id;
            window.location.reload();
        } else {
            window.location.hash = "designer/" + dashboard.id;
            window.location.reload();
        }
	}

    static isOAuthRedirect = () : boolean => {
        return !!window.location.hash &&
                window.location.hash.replace('#', '').startsWith('code=');
    }

    static getUrlParameter = (sParam:string) => {
        var sPageURL = window.location.search.substring(1);

        //If the search is empty, use the hash
        if(!sPageURL) {
            sPageURL = window.location.hash.substring(1);
        }

        var sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;
      
        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');
      
            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? "" : decodeURIComponent(sParameterName[1]);
            }
        }
        return "";
    };

    static getPageFromHash = () : RoutingInfo => {

        //Detect the active page from the url hash and start loading process
        if(window.location.hash) {

            let hashParts = window.location.hash.replace('#', '').split('/');

            return {
                page: hashParts[0],
                argument: hashParts[1]
            }
            
        }
    
        return {
            page: "",
            argument: undefined
        };
    }
      
    setInitialPageFromHash = () => {

        //Detect the active page from the url hash and start loading process
        let routing = RootState.getPageFromHash();

        this.setPage(routing.page || "dashboards");

        if(routing.argument) {
            if(routing.page === 'designer') {
                this.dashboard.loadDashboardById(routing.argument, false);

            } else if(routing.page === 'viewer' || routing.page === 'viewertab') {
                this.dashboard.loadDashboardById(routing.argument, true);
            }
        }
            
    }

    setPage = (key: string) => {

        if(this.page !== key) {
            console.log(`page changing from ${this.page} to ${key}`);
            this.setAppTitle("", "");

            this.page = key;

            if(this.page !== "loggedout") {
                this.account.load(); //Always load the account info
            }

            if(this.page === "dashboards") {
                this.dashboardsState.loadDashboards();
            } else if(this.page === "viewer" || this.page === "viewertab") {
                this.hidePageNavigation = true;
                this.readonly = true;
            } else if(this.page === "configuretab") {
                this.dashboardsState.loadDashboards();
                this.hidePageNavigation = true;
            } else if(this.page === "viewerlist") {
                this.hidePageNavigation = true;
                this.dashboardsState.loadDashboards();
            } else if(this.page === 'authComplete') {
                this.hidePageNavigation = true;
            }
        }
    }

}
