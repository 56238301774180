import { observer } from 'mobx-react';

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const LoggedOut = observer(() => {

	return <div>You are logged out.</div>;

});
