import Dialog, { DialogType } from "@fluentui/react/lib/Dialog";
import { observer } from "mobx-react";
import React from "react";
import SubscriptionState from "../state/SubscriptionState";
import '../App.css';
import { SubscriptionInfo } from "./SubscriptionInfo";
import { Label } from "@fluentui/react";

export const StandaloneSaasActivation = observer(({ subscription }: { subscription: SubscriptionState }) => {

  let modelProps = {
    isBlocking: true,
    styles: { main: { maxWidth: 700 } },
    };
    const dialogContentProps = {
    type: DialogType.largeHeader,
    title: subscription.preExistingSubscription ? 'Subscription already exists' : 'Subscription confirmed',
    };
    


  return <>
  	<Dialog
        hidden={false}
        dialogContentProps={dialogContentProps}
        modalProps={modelProps}
      >
        <>
          <SubscriptionInfo state={subscription}></SubscriptionInfo>


          { subscription.confirmedPaymentToken ? <Label>You may close this window and return to Microsoft Teams.</Label> : <></>}
      </>
      </Dialog>
  </>;
}
);
