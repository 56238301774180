/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md 
 */
 export const msalConfig = {
    auth: {
        clientId: "30ae88ad-2af7-48bd-988d-0b6391b3349e",
        authority: "https://login.microsoftonline.com/common",
        redirectUri: "https://web.listburst.app",
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

// Add here the endpoints and scopes for the web API you would like to use.
export const apiConfig = {
    uri: "https://listburst-api.azurewebsites.net", // e.g. http://localhost:5000/api
    usageUri: "https://365automate-usage.azurewebsites.net",
    scopes: ["api://web.listburst.app/30ae88ad-2af7-48bd-988d-0b6391b3349e/.default"]
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
 export const loginRequest = {
    scopes: ["openid", "profile", "api://web.listburst.app/30ae88ad-2af7-48bd-988d-0b6391b3349e/.default"],
    scopesOutsideOfTeams: ["openid", "profile", "30ae88ad-2af7-48bd-988d-0b6391b3349e/.default"],
    state: ""
};


//Updates for debugging
if(window.location.hostname === 'localhost' || window.location.hostname === 'jcardy.ngrok.io') {
    //msalConfig.auth.clientId = '09b8a663-67f3-4e29-94d5-5c1358fe689f';
    msalConfig.auth.clientId = '73d30302-40b9-4d26-a879-626fb1fb1c90';
    msalConfig.auth.redirectUri = 'https://jcardy.ngrok.io';
    apiConfig.uri = 'https://localhost:44351';
    apiConfig.usageUri = "https://localhost:44368";
    apiConfig.scopes = ["api://jcardy.ngrok.io/73d30302-40b9-4d26-a879-626fb1fb1c90/.default"];
    loginRequest.scopes = ["openid", "profile", "api://jcardy.ngrok.io/73d30302-40b9-4d26-a879-626fb1fb1c90/.default"];
    loginRequest.scopesOutsideOfTeams = ["openid", "profile", "73d30302-40b9-4d26-a879-626fb1fb1c90/.default"]
}

apiConfig.uri = apiConfig.uri + '/api';
apiConfig.usageUri = apiConfig.usageUri + '/api';

/**
 * Scopes you add here will be used to request a token from Azure AD to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

//const tokenRequest = {
//    scopes: [...apiConfig.scopes],
//};

// exporting config object for jest
if (typeof exports !== 'undefined') {
    module.exports = {
        msalConfig: msalConfig,
    };
}