import { ILinkStyles, Link } from '@fluentui/react';
import { IStackItemStyles, IStackTokens, Stack } from '@fluentui/react/lib/Stack';
import { observer } from 'mobx-react-lite';
import { RootState } from '../state/RootState';

const themedSmallStackTokens: IStackTokens = {
	childrenGap: 's1',
	padding: 's1'
  };
  
  
const headerStyles: IStackItemStyles = {
	root: {
	  textAlign: 'left',
	  color: '#D9D9D9',
	  fontSize: 20
	}
  };
  
  const linkStyles: ILinkStyles = {
	root: {
	  color: '#D9D9D9',
	  cursor: 'pointer'
	}
  };
  
  const navBarStyles: IStackItemStyles = {
	root: {
	  height: 48,
	  marginBottom:20,
	  backgroundColor:'#125C22'
	}
  };
  
  const titleStyles: IStackItemStyles = {
	root: {
	  textAlign:'center',
	  color: '#D9D9D9',
	  fontSize: 16,
	  fontWeight:'bold',
	  marginTop:'4px'
	}
  };


  export interface IHeaderProps {
    RootState:RootState;
	isAuthenticated:boolean;
  }


/**
 * Renders a button which, when selected, will open a popup for login
 */
export const Header = observer((props:IHeaderProps) => {

	if(props.RootState.hidePageNavigation)
		return <></>;


	return <Stack horizontal tokens={themedSmallStackTokens} styles={navBarStyles}>
			  
			  <Stack.Item styles={headerStyles}>
			  ListBurst for Teams
			  </Stack.Item>

			  <Stack.Item grow styles={titleStyles}>
				  { props.RootState.appTitle ?
				  	<Link href={props.RootState.appTitleLink} styles={linkStyles}>{props.RootState.appTitle}</Link> : <></> }
			  </Stack.Item>

		  </Stack>;
	
	
});
