import { makeAutoObservable } from "mobx";
import API, { IAccountInfo } from "../API";
import SubscriptionState from "./SubscriptionState";

export default class AccountState {

	public loading:boolean = false; 
    private loaded:boolean = false;

	public message:string = "";
    public banner:string = "";
    public username:string = "";
    public email:string = "";
	public showBanner = false;

	private getToken: () => Promise<string>;

	public subscriptionState:SubscriptionState;

	constructor(getToken: () => Promise<string>) {
		makeAutoObservable(this);

		this.getToken = getToken;
		this.hideBanner = this.hideBanner.bind(this);
		this.subscriptionState = new SubscriptionState();
	}

	public load(force:boolean = false) : void {
		
		if(force || (!this.loaded && !this.loading)) {
			this.loading = true;
			this.getToken().then(async token => {
				let accountInfo = await new API(token).getAccountInfo();
				this.setInfo(accountInfo);
				this.loading = false;
				this.loaded = true;
			}).catch(() => {
				this.loaded = false;
				this.loading = false;
			});
		}
	}

	public hideBanner() : void {
		this.showBanner = false;
	}

	private setInfo(accountInfo: IAccountInfo) : void {
		this.message = accountInfo.message;
		this.banner = accountInfo.banner;
		this.email = accountInfo.email;
		this.username = accountInfo.username;
		this.subscriptionState.setSubscription(accountInfo.subscription);

		if(this.banner) {
			this.showBanner = true;
		}
	}
}
