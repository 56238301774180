import { apiConfig } from "./authConfig";
import { Dashboard } from "./state/DashboardState";
import { IChartProperties } from "./state/EditChartState";

export default class API {
    
    private accessToken:string;
    constructor(accessToken:string) {
        this.accessToken = accessToken;
    }

    public async checkSPAuth(webUrl:string): Promise<ICheckAuthResult<string>> {
        return await this.sendRequest("GET", `/Account/CheckSPAuth?webUrl=${encodeURIComponent(webUrl)}`);
    }

    public async getDashboards(): Promise<Dashboard[]> {
        return await this.sendRequest("GET", "/Dashboard/List");
    }

    public async getDashboard(id:string): Promise<Dashboard> {
        return await this.sendRequest("GET", `/Dashboard?id=${id}`);
    }

    public async saveDashboard(dashboard:Dashboard) : Promise<string> {
        return await this.sendRequest("POST", "/Dashboard/Upsert", {
            id: dashboard.id,
            title: dashboard.title,
            published: dashboard.published
        });
    }

    public async getCharts(dashboardId:string): Promise<IChartProperties[]> {
        return await this.sendRequest("GET", "/Chart?dashboardId=" + dashboardId);
    }

    public async saveChart(chart:IChartProperties, dashboardId:string, coordinatesOnly:boolean = false) : Promise<string> {
        
        if(!coordinatesOnly) {
            return await this.sendRequest("POST", "/Chart/Upsert?dashboardId=" + dashboardId, chart);
        } else {
            await this.sendRequest("POST", "/Chart/UpdatePosition?dashboardId=" + dashboardId, {
                id: chart.id,
                X: chart.x,
                Y: chart.y,
                width: chart.width,
                height: chart.height
            });
            return chart.id;
        }
    }
    
    public async deleteChart(chartId:string): Promise<void> {
        return await this.sendRequest("DELETE", "/Chart?chartId=" + chartId);
    }

    public async getFields(listUrl:string): Promise<ISPField[]> {
        return await this.sendRequest("GET", "/Rendering/Fields?listUrl=" + encodeURIComponent(listUrl));
    }

    public async getSeries(listUrl:string, numericField:string, categoryField:string, aggregation:string): Promise<ISeriesAuthAPIResult> {
        return await this.sendRequest("GET", `/Rendering/Series?numericField=${numericField}&categoryField=${categoryField}&aggregation=${aggregation}&listUrl=${encodeURIComponent(listUrl)}`);
    }

    public async getAccountInfo(): Promise<IAccountInfo> {
        return await this.sendRequest("GET", `/Account`);
    }

    public async processPaymentToken(token:string): Promise<ISubscription|IPreexistingSubscription> {
        return await this.sendRequest("POST", "/TeamsSaasWebhook/ProcessToken", {
            token: token
        }, true);
    }

    private async sendRequest(method:string, path:string, body:object|undefined = undefined, isUsageApi:boolean = false) {
        const headers = new Headers();
        const bearer = `Bearer ${this.accessToken}`;
    
        headers.append("Authorization", bearer);

        if(body){
            
            headers.append("Accept", 'application/json');
            headers.append("Content-Type", 'application/json');
        }
    
        const options:RequestInit = {
            method: method,
            headers: headers
        };


        if(body) {
            options.body = JSON.stringify(body);
        }

        let uri = isUsageApi ? apiConfig.usageUri : apiConfig.uri;

        return fetch(uri + path, options)
            .then(response => response.json())
            .catch(error => console.log(error));
    }
}


export interface ISPField {
    internalName: string;
    title:string;
    type:number;
    allowMultipleValues:boolean;
}

export interface ISeriesAuthAPIResult {
    result: ISeries;
    launchAuthenticationURL: boolean;
}

export interface ISeries {
    points:IPoint[];
    groupedByField:string;
    aggregation:string;
}

export interface IPoint {
    value:number;
    category:string;
    count:number;
}

export interface IAccountInfo {
    message:string;
    banner:string;
    username:string;
    email:string;
    subscription:ISubscription;
}

export interface ISubscription {
	id: string;
	tenantId: string;
	ownerId: string;
	startDate: Date;
	endDate: Date;
	offerId: string;
	planId: string;
	purchaserEmail: string;
    isActive:boolean;
}

export interface IPreexistingSubscription {
    preexistingSubscriptionId: string;
    planId: string;
    activatedDate: Date;
}

export interface ICheckAuthResult<TResult> {
    launchAuthenticationURL: boolean;
    result: TResult;
}
