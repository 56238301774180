import { MessageBar } from "@fluentui/react";
import { observer } from "mobx-react";
import { RootState } from "../../state/RootState";
import { AccountInfo } from "./AccountInfo";
import { ConfigureTabPage } from "./ConfigureTabPage";
import { DashboardPage } from "./DashboardPage";
import { DashboardsListPage } from "./DashboardsListPage";
import { GrantSPPermissions } from "./GrantSPPermissions";
import { LoggedOut } from "./LoggedOut";

let getPage = (rootState:RootState) => {

  if(rootState.spAuth.spAuthRequired)
    return <GrantSPPermissions RootState={rootState}></GrantSPPermissions>;

  if(rootState.page === "loggedout")
    return <LoggedOut></LoggedOut>;

  if(rootState.page === "configuretab")
    return <ConfigureTabPage RootState={rootState}></ConfigureTabPage>

  //viewer and viewertab are the same, but viewertab indicates to the page that it should use Teams SSO.
  if(rootState.page === "designer" || rootState.page === "viewer" || rootState.page === "viewertab")
    return <DashboardPage RootState={rootState}></DashboardPage>
    
  if(rootState.page === "account")
    return <AccountInfo RootState={rootState}></AccountInfo>

  if(rootState.page === "dashboards" || rootState.page === "viewerlist")
    return <DashboardsListPage RootState={rootState}></DashboardsListPage>

  //Sometimes we can get to this point because the login redirect didn't happen. If that's the case, redirect now
  if(window.location.hash.startsWith("#code=")) {
    return <div>Completing login...</div>;
  }

  return <div>page not found: {rootState.page}</div>
};

const getBanner = (rootState:RootState) => {

  if(!rootState.account.showBanner || rootState.isEmbeddedOrMobile || rootState.hidePageNavigation)
    return <></>;
    
  return <MessageBar onDismiss={rootState.account.hideBanner}>{rootState.account.banner}</MessageBar>;

};

export const PageRouter = observer(({ rootState }: { rootState: RootState }) => 
  {
    return <>
      {getBanner(rootState)}

      {getPage(rootState)}
    </>
  }
);

