import React from "react";
import './App.css';
import 'gridstack/dist/gridstack.min.css';
import 'gridstack/dist/h5/gridstack-dd-native';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { IRootStateProps, RootState } from "./state/RootState";
import { SignInButton } from "./components/SignInButton";
import { observer } from "mobx-react";
import { IStackItemStyles, Stack } from "@fluentui/react/lib/Stack";
import { INavLink, INavLinkGroup, INavStyles, Nav } from "@fluentui/react";
import { PageRouter } from "./components/pages/PageRouter";
import { MSALAuth } from "./state/authentication/MSALAuth";
import { Header } from "./components/Header";


const mainPageStyles: IStackItemStyles = {
  root: {
    width:'100%',
    minHeight: '100%',
    paddingLeft:10
  }
};

const navStyles: Partial<INavStyles> = {
  root: {
    width: 208,
    overflowY: 'auto',
  },
};

const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Dashboards',
        url: '#dashboards',
        key: 'dashboards',
        icon: 'ViewList'
      },
      {
        name: 'Account',
        url: '#account',
        icon: 'AccountManagement',
        key: 'account'
      },
    ],
  },
];



const LeftNav = observer(({ rootState }: { rootState: RootState }) => 
  {
    return <Nav
                      onLinkClick={(ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => { rootState.setPage(item?.key || "") }}
                      selectedKey={rootState.page}
                      ariaLabel="Navigation"
                      styles={navStyles}
                      groups={navLinkGroups}
                    />
  }
);

export const App: React.FunctionComponent<IRootStateProps> = (props:IRootStateProps) => {

  //Get the MSAL context object. This only works if this component is rendered inside a MsalProvider component.
  let msalContext = useMsal();
  let isAuthenticated = useIsAuthenticated();
  
  let rootState = props.RootState;

  //if(!rootState.page) {
    //This should always be an instance of MSALAuth since this App component is only used with MSAL.
    if(rootState.auth instanceof MSALAuth) {
      (rootState.auth as MSALAuth).setContext(msalContext);
    }

    rootState.setInitialPageFromHash();
  //}

  return (
    <Stack>
          
          {/* Top navigation bar - only if not in readonly mode */}
          <Stack.Item align="auto">
            <Header isAuthenticated={isAuthenticated} RootState={rootState}></Header>
          </Stack.Item>

          {/* Everything below the top navigation */}
          <Stack.Item>

            <Stack horizontal>
              
                {/* Left nav - only if logged in and not in readonly mode */}
                { (rootState.hidePageNavigation || !isAuthenticated) || 
                  <Stack.Item>
                    <LeftNav rootState={rootState}></LeftNav>
                  </Stack.Item> }


                {/* Main page content */}
              <Stack.Item styles={mainPageStyles}>
                <AuthenticatedTemplate>
                  <PageRouter rootState={rootState}></PageRouter>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div style={{boxShadow:'rgb(0 0 0 / 40%) 0px 0px 5px 0px', maxWidth:'600px', padding:'25px'}}>
                      <h1>Welcome to ListBurst.</h1>
                      <p>Sign in below using your Microsoft 365 account to start.</p>
                      <SignInButton RootState={rootState}></SignInButton>
                    </div>
                </UnauthenticatedTemplate>
              </Stack.Item>
            </Stack>


          </Stack.Item>
      </Stack>

  )

};
