import { IAuth } from "./IAuth";
import * as microsoftTeams from "@microsoft/teams-js";
import { makeAutoObservable } from "mobx";
import { Dashboard } from "../DashboardState";

export class TeamsSSOAuth implements IAuth {

	selectedDashboard:Dashboard|null = null;
	context:microsoftTeams.app.Context|null = null;

	public contextLoad: Promise<microsoftTeams.app.Context>;

	constructor() {
		makeAutoObservable(this);
		microsoftTeams.app.initialize();
		this.contextLoad = microsoftTeams.app.getContext();
		
		this.contextLoad.then(this.setTeamsContext.bind(this)).catch(reason => {
			console.log("failed to load teams " + reason);
		});
	}

	selectDashboard(dashboard: Dashboard) {
        this.selectedDashboard = dashboard;
		microsoftTeams.pages.config.setValidityState(true);
    }

	setTeamsContext = (ctx:microsoftTeams.app.Context) => {
		this.context = ctx;
		
		microsoftTeams.pages.config.registerOnSaveHandler((saveEvent: microsoftTeams.settings.SaveEvent) => {
			const host = "https://" + window.location.host;
			microsoftTeams.pages.config.setConfig({
				contentUrl: host + "?name={loginHint}&tenant={tid}&group={groupId}&theme={theme}#viewer/" + this.selectedDashboard?.id,
				websiteUrl: host + "?name={loginHint}&tenant={tid}&group={groupId}&theme={theme}#viewer/" + this.selectedDashboard?.id,
				suggestedDisplayName: this.selectedDashboard?.title || "Dashboard"
			});
			saveEvent.notifySuccess();
		});
		microsoftTeams.pages.config.setValidityState(false);
		microsoftTeams.app.notifySuccess();
	}

	login = () => {
		throw new Error("invalid operation");
	};
	
	logout = () => {
		throw new Error("invalid operation");
	};

	getToken = () : Promise<string> => {
        return new Promise<string>((resolve,reject) => {

			this.contextLoad.then(() => {

				microsoftTeams.authentication.getAuthToken({
				}).then(token => {
					resolve(token);
					microsoftTeams.app.notifySuccess();
				}).catch(reason => {
					reject(reason);
					microsoftTeams.app.notifyFailure({
						reason: microsoftTeams.app.FailedReason.AuthFailed,
						message: reason
					});
				});
			});
        });
    }

}