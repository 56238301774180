import { Dialog, DialogFooter, DialogType, PrimaryButton, Spinner } from '@fluentui/react';
import { observer } from 'mobx-react';
import { IRootStateProps } from '../../state/RootState';
import * as microsoftTeams from "@microsoft/teams-js";

  const modelProps = {
	isBlocking: false,
	styles: { main: { maxWidth: 450 } },
  };
  const dialogContentProps = {
	type: DialogType.normal,
	title: 'Permission required',
	subText: 'To view your dashboards, you must grant consent for SharePoint access. Click below to continue.',
  };
  
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const GrantSPPermissions = observer((state: IRootStateProps) => {

	if(!state.RootState.spAuth.spAuthRequired) {
		return <div>Shouldn't get here</div>;
	}

	if(state.RootState.spAuth.spAuthInProgress) {
		return <Spinner label="Connecting you to your dashboards for the first time. Please wait a moment...." />;
	} else {

		return <Dialog dialogContentProps={dialogContentProps} modalProps={modelProps} hidden={false} >
			<DialogFooter>
				<PrimaryButton style={{width:100}} onClick={() => {

					microsoftTeams.authentication.authenticate({
						url: '/#authStart',
					}).then(() => {
						//Success
						console.log("Auth success");

						state.RootState.spAuth.handleFirstTimeLogin();

					}, () => {
						//reject
						console.log("Auth reject");
					});
					}}>
					Consent
				</PrimaryButton>
			</DialogFooter>
		</Dialog>;

	}

});
