import API from "../../API";
import { makeAutoObservable } from "mobx";

export class SPAuth {
	
    public spAuthRequired:boolean = false;
    public spAuthInProgress:boolean = false;
    public tenantId:string = '';
	public authWebUrl:string = '';

	private getToken: () => Promise<string>;

	constructor(getToken: () => Promise<string>) {
        makeAutoObservable(this);
		this.getToken = getToken;
	}

    setSPAuthRequired(spAuthRequired: boolean) {
        this.spAuthRequired = spAuthRequired;
		this.spAuthInProgress = false;
    }
  
    handleFirstTimeLogin() {
        this.spAuthInProgress = true;

		let attempts = 0;

		let interval = setInterval(() => {

			this.getToken().then(token => {
				attempts++;
				new API(token).checkSPAuth(this.authWebUrl).then(result => {

					console.log(`Auth attempt: ${attempts}, result: ${result.launchAuthenticationURL}`);

					if(!result.launchAuthenticationURL) {
						window.location.reload();
					}

				});
			});

			if(attempts > 10) {
				clearInterval(interval);
				window.location.reload();// Just try a reload anyway
			}
		}, 1000);

    }

	setSPAuthContext = (webUrl:string, tenantId:string) => {
        this.authWebUrl = webUrl;
		this.tenantId = tenantId;
	}

	startCheckSPAuth = () => {
		this.getToken().then(token => {
			new API(token).checkSPAuth(this.authWebUrl).then(result => {
				this.setSPAuthRequired(result.launchAuthenticationURL);
			});
		});
	}
}