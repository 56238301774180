import { GridItemHTMLElement, GridStack, GridStackNode } from "gridstack";
import React from "react";
import DashboardState from "../state/DashboardState";
import ChartRenderer from "./ChartRenderer";

export interface IDashboardListProps {
  state: DashboardState;
}

export class CanvasRenderer extends React.Component<IDashboardListProps> {

    detectChartLocation(chart:ChartRenderer) : boolean {
      let domElem = document.querySelector("[gs-id='"+ chart.id +"']", );
      let updated:boolean = false;
      
      if(domElem) {
        let x = domElem.attributes.getNamedItem("gs-x")?.value;
        let y = domElem.attributes.getNamedItem("gs-y")?.value;
        let w = domElem.attributes.getNamedItem("gs-w")?.value;
        let h = domElem.attributes.getNamedItem("gs-h")?.value;

        if(x && y && w && h) {

          if(chart.width !== Number(w)) {
            chart.width = Number(w);
            updated = true;
          }

          if(chart.height !== Number(h)) {
            chart.height = Number(h);
            updated = true;
          }

          if(chart.x !== Number(x)) {
            chart.x = Number(x);
            updated = true;
          }

          if(chart.y !== Number(y)) {
            chart.y = Number(y);
            updated = true;
          }
        }
      }

      return updated;
    }


    componentDidMount() {

      // Provides access to the GridStack instance across the React component.
      this.props.state.grid = GridStack.init({
        float: true,
        minRow: 12,
        margin:3,
        disableOneColumnMode: true,
        disableDrag: this.props.state.readOnly,
        disableResize: this.props.state.readOnly
      });

      console.log("Grid initialized");

      this.props.state.grid.on("changed", (_, el:any) => {
        console.log("changed" + el);
      });

      this.props.state.grid.on("dragstop", (_, el:any) => {
        let elem = el as GridItemHTMLElement;
        if(elem) {
            let resizedNode: GridStackNode = el.gridstackNode;
            let chart = this.props.state.charts.filter(c => c.id === resizedNode.id)[0];
            if(chart && resizedNode.x !== undefined && resizedNode.y !== undefined) {
              chart.setNode(resizedNode);
              
              //go through all charts and save their positions
              //It would be better to detect the ones that actually moved.
              this.props.state.charts.forEach(c => {

                //Re-set the chart location and size from the grid. It may have moved in response to another chart moving.
                if(this.detectChartLocation(c)) {
                  this.props.state.saveChartCoordinates(c);
                }
              });

            }
        }
      });

      this.props.state.grid.on('resizestop', (_, el: any) => {
        let elem = el as GridItemHTMLElement;
        if(elem) {
            let resizedNode: GridStackNode = el.gridstackNode;
            
            let chart = this.props.state.charts.filter(c => c.id === resizedNode.id)[0];
            if(chart && resizedNode.w !== undefined && resizedNode.h !== undefined) {
              chart.setNode(resizedNode);
              chart.draw();

              //go through all charts and save their positions
              //It would be better to detect the ones that actually moved.
              this.props.state.charts.forEach(c => {

                //Re-set the chart location and size from the grid. It may have moved in response to another chart moving.
                if(this.detectChartLocation(c)) {
                  this.props.state.saveChartCoordinates(c);
                }
              });
            }
        }
      });

      //Handle window resizing - causes all widgets to resize
      window.addEventListener('resize', () => {
        this.props.state.charts.forEach(c => {
          c.draw();
        });
      });

      
      google.charts.load('current', {'packages':['corechart', 'table']});
      
      //Add any pre-loaded charts to the new grid
      google.charts.setOnLoadCallback(() => {
        this.props.state.charts.forEach(c => c.addToGrid(this.props.state.grid as GridStack));
      });

    }

    render() {
      return (
        <div>
          <section className="grid-stack"></section>
        </div>
      );
    }
  }