import { CommandBar, ICommandBarItemProps, IStackItemStyles, Pivot, PivotItem, Stack } from "@fluentui/react";
import { observer } from "mobx-react";
import { IRootStateProps } from "../../state/RootState";
import { AddDashboardDialog } from "../AddDashboardDialog";
import { DashboardsList } from "../DashboardsList";
    
  const headerStyles: IStackItemStyles = {
    root: {
      textAlign: 'left',
      color: '#D9D9D9',
      fontSize: 20
    }
  };

let getCommandBarButtons = (state:IRootStateProps) : ICommandBarItemProps[] => {

  let result = [];

  if(state.RootState.isEmbeddedOrMobile) {
    result.push({
      key: 'newItem',
      text: 'Create',
      iconProps: { iconName: 'NavigateExternalInline' },
      onClick: () => {window.open("#dashboards", "_blank")}
    });
  } else {

    result.push({
      key: 'newItem',
      text: 'Create new dashboard',
      iconProps: { iconName: 'Add' },
      onClick: () => state.RootState.addDashboardState.startAddDashboard()
    });
  }

  result.push({
    key: 'reload',
    text: 'Refresh',
    iconProps: { iconName: 'Refresh' },
    onClick: () => state.RootState.dashboardsState.loadDashboards()
  });

  return result;
};

export const DashboardsListPage = observer((state:IRootStateProps) => {
    return (
      <>
        <AddDashboardDialog state={state.RootState.addDashboardState}></AddDashboardDialog>
        <Stack>
            <Stack.Item>
                <Stack horizontal>
                    <Stack.Item>
                      <CommandBar items={getCommandBarButtons(state)}></CommandBar>
                    </Stack.Item>
                    <Stack.Item grow styles={headerStyles}>
                        <Pivot aria-label="Dashboards">
                            <PivotItem headerText="My Dashboards">
                            </PivotItem>
                        </Pivot>
                    </Stack.Item>
                </Stack>
                
            </Stack.Item>
            <Stack.Item>
                <DashboardsList RootState={state.RootState}></DashboardsList>
            </Stack.Item>
        </Stack>
      </>
    );
});