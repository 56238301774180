import { Label, Spinner } from "@fluentui/react";
import SubscriptionState from "../state/SubscriptionState";
import { observer } from "mobx-react";

/**
 * Renders subscription information including SaaS token activation
 */
export const SubscriptionInfo = observer(({state}: {state:SubscriptionState}) => {

    let getSubscriptionInfo = () => {

        let sub = state.subscription;
        if(!sub) {
            return <></>;
        } else {
            return <div>
                <div>Subscription Owner: <span>{sub.purchaserEmail}</span></div>
                <div>Status: {sub.isActive ? 'DISABLED' : 'ACTIVE'}</div>
                <div>Current subscription period: <span>{new Date(sub.startDate).toDateString()}</span> to <span>{new Date(sub.endDate).toDateString()}</span>.</div>
            </div>;
        }
    };

    let getSubscriptionConfirmation = () => {


        if(state.confirmingPaymentToken) {
            return <>
                <Spinner label="Confirming subscription..." ariaLive="assertive" labelPosition="top" />
            </>;
        }

        if(state.confirmedPaymentToken) {
            return (
                <>      
                    <Label>Thanks for subscribing!</Label>
            
                </>
            );
        }
    };

        

    let getPreExistingSubscriptionWarning = () => {
        return <>
            <div>This transaction was cancelled because your tenant already has an active subscription. Please modify the existing subscription or contact your admin to add more users to your existing subscription.</div>
            <div>For more information please <a href="mailto:contact@365automate.com">contact us</a>.</div>
        </>;
    }


    if(state.preExistingSubscription) {
        return <>{getPreExistingSubscriptionWarning()}</>;
    } else {

        return (<>
            {getSubscriptionConfirmation()}
            {getSubscriptionInfo()}
        </>);
    }
});