import { makeAutoObservable } from "mobx";
import API, { ISPField } from "../API";
import ChartRenderer from "../rendering/ChartRenderer";
import DashboardState from "./DashboardState";

export interface IChartProperties {
	//Chart
	id: string;
	title: string;
	x: number;
	y: number;
	width: number;
	height: number;

	//Data source/series props
	listUrl: string;
	type:string;
	numericFieldInternalName:string;
	aggregationType:string;
	categoryFieldInternalName:string;
}

export default class EditChartState implements IChartProperties  {
	public id: string = "";
	public title: string = "";
	public x: number = 0;
	public y: number = 0;
	public width: number = 0;
	public height: number = 0;

	public listUrl: string = "";
	public numericFieldInternalName:string = "";
	public categoryFieldInternalName:string = "";
	public aggregationType: string = "";
	public type:string = "pie";

	public fields: ISPField[] = [];

	private getToken: () => Promise<string>;

	private dashboardState:DashboardState;

	public loading:boolean = true;
	public saving:boolean = false;

	//This object stores the state from before a chart is edited, so that it can be reverted if 'cancel' is clicked.
	private originalState:ChartRenderer|null = null;
    
	constructor(getToken: () => Promise<string>, dashboardState:DashboardState) {
		makeAutoObservable(this);

		this.getToken = getToken;
		this.dashboardState = dashboardState;
	}

	//Should be called when the selected chart changes. Loads the fields for the current chart.
	loadSelectedChart = () => {
		if(this.dashboardState.selectedChart) {
			this.loading = true;

			//Save the original state
			this.originalState = new ChartRenderer(this.dashboardState.selectedChart, this.getToken);

			//Copy all the properties across for editing
			EditChartState.copyProperties(this.dashboardState.selectedChart, this);

			//Get the fields
			this.getToken().then(token => {
				new API(token).getFields(this.listUrl).then(fields => {
					this.fields = fields;
					this.loading = false;
				})
			});
		}
	}

	apply = () => {
		if(this.dashboardState.selectedChart) {
			//Copy properties to the destination and save it
			EditChartState.copyProperties(this, this.dashboardState.selectedChart);
			this.dashboardState.selectedChart.loadSeries();
		}
	}

	applyAndClose = () => {
		this.apply();

		this.saving = true;
		
		//Get the fields
		this.getToken().then(token => {

			new API(token).saveChart(new ChartRenderer(this, this.getToken), this.dashboardState.dashboard?.id || "").then(chartId => {
				console.log("Saved and got ID back: " + chartId);

				if(this.dashboardState.selectedChart) {
					//Store the new ID
					this.dashboardState.selectedChart.id = chartId;
				}

				this.saving = false;
				this.originalState = null; //No need to keep  this
				this.dashboardState.setSelectedChart(null);		
			})
		});

	}

	revert = () => {
		//Copy the properties back to what they were originally
		if(this.dashboardState.selectedChart && this.originalState !== null) {
			EditChartState.copyProperties(this.originalState, this.dashboardState.selectedChart);
			this.dashboardState.selectedChart.loadSeries();
		}
	}

	revertAndClose = () => {
		this.revert();
		this.originalState = null; //No need to keep  this
		this.dashboardState.setSelectedChart(null);
	}

	setNumericField(numericFieldInternalName:string) {
		this.numericFieldInternalName = numericFieldInternalName;
		this.apply();
	}
	
	setCategoryField(categoryFieldInternalName:string) {
		this.categoryFieldInternalName = categoryFieldInternalName;
		this.apply();
	}

	setAggregationType(aggregationType: string) {
		this.aggregationType = aggregationType;
		this.apply();
	}
	
	setType(type: string) {
		this.type = type;
		this.apply();
	}

	setTitle(v: string): void {
		this.title = v;
		this.apply();
	}

	
	public static copyProperties(source:IChartProperties, destination:IChartProperties) {
		destination.id = source.id;
		destination.x = source.x;
		destination.y = source.y;
		destination.height = source.height;
		destination.width = source.width;
		destination.title = source.title || "";
		destination.type = source.type;

		destination.listUrl = source.listUrl;
		destination.numericFieldInternalName = source.numericFieldInternalName;
		destination.categoryFieldInternalName = source.categoryFieldInternalName;
		destination.aggregationType = source.aggregationType;
	}

}

