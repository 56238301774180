import { DetailsListLayoutMode, IColumn, SelectionMode } from "@fluentui/react/lib/DetailsList";
import { MessageBar } from "@fluentui/react/lib/MessageBar";
import { IShimmeredDetailsListStyles, ShimmeredDetailsList } from "@fluentui/react/lib/ShimmeredDetailsList";
import { observer } from "mobx-react";
import { Dashboard } from "../state/DashboardState";
import { IRootStateProps } from "../state/RootState";

const columns: IColumn[] = [
    {
        key: 'nameColumn',
        name: 'Name',
        fieldName: 'Name',
        minWidth: 210,
        maxWidth: 800,
        isRowHeader: true,
        isResizable: true,
        data: 'string',
        isPadded: true
    }];

    const styles: IShimmeredDetailsListStyles = {
        root: {
          cursor:'hand'
        }
      };
  
export class DashboardListItem {
    public key: string = "";
    public Name: string = "";

    public dashboard: Dashboard;

    constructor(dashboard:Dashboard) {
        this.dashboard = dashboard;
        this.key = dashboard.id;
        this.Name = dashboard.title;
    }
}
  
  
/**
 * Renders a button which, when selected, will open a popup for login
 */
export const DashboardsList = observer((state:IRootStateProps) => {

    let message = state.RootState.isEmbeddedOrMobile ? 'Create' : 'Create new dashboard';

    if(!state.RootState.dashboardsState.loadingDashboards && !state.RootState.dashboardsState.dashboards.length) {
        return <MessageBar>
            You have no dashboards. Click '{message}' to get started.
        </MessageBar>;
    } else {

	    return <ShimmeredDetailsList
                setKey="items"
                styles={styles}
                items={state.RootState.dashboardsState.dashboards.map(r => new DashboardListItem(r))}
                compact={false}
                columns={columns}
                selectionMode={SelectionMode.single}
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onActiveItemChanged={(item:DashboardListItem) => { 
					
                    state.RootState.selectDashboard(item.dashboard);
                }}
                enableShimmer={state.RootState.dashboardsState.loadingDashboards}
                selectionPreservedOnEmptyClick={true}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                ariaLabelForShimmer="Dashboards are being fetched"
                ariaLabelForGrid="Dashboards list"
                />
    }
});
