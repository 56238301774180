import { DefaultButton, IIconProps, Label, PrimaryButton } from '@fluentui/react';
import { observer } from 'mobx-react';
import { IRootStateProps } from '../../state/RootState';
import { DashboardsList } from '../DashboardsList';

const refreshIcon: IIconProps = { iconName: 'Refresh' };
const navigateIcon: IIconProps = { iconName: 'NavigateExternalInline' };

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const ConfigureTabPage = observer((state: IRootStateProps) => {

	if(!state.RootState.dashboardsState.loadingDashboards && state.RootState.dashboardsState.dashboards.length === 0) {
		//There aren't any dashboards. Show a button to create some.
		return <>
			<Label>You do not have any dashboards. Please create a dashboard before adding your tab.</Label>

			<div >
				<DefaultButton iconProps={refreshIcon} 
					onClick={() => state.RootState.dashboardsState.loadDashboards()}>Refresh</DefaultButton>
				<PrimaryButton iconProps={navigateIcon} style={{marginLeft:10}} 
					title='Open ListBurst to create a dashboard before adding the tab'
					onClick={() => window.open("#dashboards", "_blank")}>Create a Dashboard</PrimaryButton>
			</div>
		</>;
	} else {
		return <>
			<Label>Select the dashboard to display in your new tab.</Label>
			<DashboardsList RootState={state.RootState}></DashboardsList>
		</>;
	}
});

