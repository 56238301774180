import { IMsalContext } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { RootState } from "../RootState";
import { IAuth } from "./IAuth";

export class MSALAuth implements IAuth {

	private context: IMsalContext|null = null;
	private name:string = "";
	private useRedirectLoginFlow:boolean;

	constructor(useRedirectLoginFlow:boolean) {
		this.useRedirectLoginFlow = useRedirectLoginFlow;
	}

    setContext = (ctx:IMsalContext) : void => {
        this.context = ctx;
    }

	getToken = () : Promise<string> => {
        return new Promise<string>((resolve,reject) => {
            if(this.context === null) {
                reject();
            } else {

                if(this.useRedirectLoginFlow) {
                    loginRequest.scopes = loginRequest.scopesOutsideOfTeams;
                }

                //Pass along a payment token if present
                let urlTokenParam = RootState.getUrlParameter('token') || RootState.getUrlParameter('state');
                loginRequest.state = urlTokenParam || "";
                
                const request = {
                    ...loginRequest,
                    account: this.context.accounts[0]
                };
        
                this.name = (this.context.accounts[0] && this.context.accounts[0].name) || "";
        
                // Silently acquires an access token which is then attached to a request for Microsoft Graph data
                this.context.instance.acquireTokenSilent(request).then((response) => {
                    resolve(response.accessToken);
                }).catch((e) => {
                    if(this.context) {
                        this.context.instance.acquireTokenRedirect(request).then((response) => {
                            resolve("token will be acquired after this redirect occurs");
                        }).catch((e) => {
                            console.log(e);
                            reject(e);
                        });
                    }
                });
            }
        });
    }

	
    login = () => {
        if(this.context) {

            let loginFlow = this.useRedirectLoginFlow ? 
                                this.context.instance.loginRedirect : 
                                this.context.instance.loginPopup;

            //Pass along a payment token if present
            let urlTokenParam = RootState.getUrlParameter('token') || RootState.getUrlParameter('state');
            loginRequest.state = urlTokenParam || "";

            loginFlow.bind(this.context.instance)(loginRequest).catch((e:any) => {
                console.error(e);
            }).then(() => {
                console.log("login complete");
            });
        }
    }


    logout = () => {
        if(this.context) {

            window.location.hash = "";

            let logoutFlow = this.useRedirectLoginFlow ? 
                                this.context.instance.logoutRedirect: 
                                this.context.instance.logoutPopup;

            logoutFlow.bind(this.context.instance)({
                
            }).catch((e:any) => {
                console.log(e);
            });
        }
    }


}