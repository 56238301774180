
import { ChoiceGroup, DefaultButton, Dropdown, IChoiceGroupOption, ISeparatorStyles, Label, PrimaryButton, Separator, Spinner, Stack, TextField } from '@fluentui/react';
import { observer } from 'mobx-react-lite';
import { ISPField } from '../API';
import EditChartState from '../state/EditChartState';

interface IEditChartDialogProps {
    state:EditChartState;
}

const options: IChoiceGroupOption[] = [
	{ key: 'column', text: 'Column', iconProps: { iconName: 'BarChartVertical' } },
	{ key: 'bar', text: 'Bar', iconProps: { iconName: 'BarChartHorizontal' } },
	{ key: 'pie', text: 'Pie', iconProps: { iconName: 'PieSingle' } },
	{ key: 'table', text: 'Data table', iconProps: { iconName: 'Table' } }
  ];

const separatorStyles:ISeparatorStyles = {
	root: { marginTop: '20px !important'},
	content: { }
};

const buttonStyles = { root: { marginRight: 8, marginTop: 8 } };

export enum FieldType {
    Invalid = 0,
    Integer = 1, //Integer
    Text = 2,
    Note = 3,
    DateTime = 4,
    Counter = 5, //Counter
    Choice = 6,
    Lookup = 7,
    Boolean = 8, //Boolean
    Number = 9, //Number
    Currency = 10, //Currency
    Computed = 12, //Computed
    MultiChoice = 15,
	Calculated = 17,
}

const getValidNumericalFields = (fields:ISPField[]) : ISPField[] => {
	let validFields = [FieldType.Integer, //Integer
            FieldType.Counter, //Counter
            FieldType.Boolean, //Boolean
            FieldType.Number, //Number
            FieldType.Currency, //Currency
            FieldType.Computed, //Computed
            FieldType.Calculated  //Calculated
        ];

	return fields.filter(f => {
		return validFields.indexOf(f.type) > -1;
	});
}

const getValidCategoryFields = (fields:ISPField[]) : ISPField[] => {
	
	return fields.filter(f => {
		return !f.allowMultipleValues && f.type !== FieldType.MultiChoice;
	});
}


/**
 * Renders a button which, when selected, will open a popup for login
 */
export const ConfigurationPane = observer((s:IEditChartDialogProps) => {
	
	let state = s.state;

	if(s.state.loading) {
		return <Spinner></Spinner>;
	}

	if(!s.state.fields) {
		return <Label>The list could not be loaded. You may not have access.</Label>;
	}

    return (
        <Stack>
			<ChoiceGroup label="Chart type" selectedKey={state.type} onChange={(_,o) => { state.setType(o?.key || ""); }} options={options} />
			
			<Separator styles={separatorStyles}>Data source</Separator>
			<Dropdown
				placeholder="Select numeric data field"
				label="Field containing the numerical data"
				options={getValidNumericalFields(s.state.fields).map(f => { return {
					key: f.internalName,
					text: f.title
				}})}
				defaultSelectedKey={state.numericFieldInternalName}
				onChange={(_, o) => { state.setNumericField(o?.key as string)}}
				required={true}
        	/>

			<Dropdown
				placeholder="Select aggregation type"
				label="Type of calculation to perform"
				options={[{key: 'None', text: 'None'}, 
						{key: 'SUM', text: 'Sum (totals)'}, 
						{key: 'COUNT', text: 'Count the items'}, 
						{key: 'AVG', text: 'Average'}, 
						{key: 'MAX', text: 'Maximum'}, 
						{key: 'MIN', text: 'Minimum'}, 
						{key: 'STDEV', text: 'Standard Deviation'}, 
						{key: 'VAR', text: 'Variance'}]}
				defaultSelectedKey={state.aggregationType}
				onChange={(_, o) => { state.setAggregationType(o?.key as string)}}
				required={true}
        	/>

			<Dropdown
				placeholder="Select category field"
				label="Field to use for categories/groupings"
				options={getValidCategoryFields(s.state.fields).map(f => { return {
					key: f.internalName,
					text: f.title
				}})}
				defaultSelectedKey={state.categoryFieldInternalName}
				onChange={(_, o) => { state.setCategoryField(o?.key as string)}}
				required={true}
        	/>

			
			<Separator styles={separatorStyles}>Display</Separator>

			<TextField label='Title' onChange={(_,v) => state.setTitle(v || "")} defaultValue={state.title}></TextField>

			<Stack horizontal>
				<PrimaryButton onClick={state.applyAndClose} styles={buttonStyles}>Save</PrimaryButton>
				<DefaultButton onClick={state.revertAndClose} styles={buttonStyles}>Cancel</DefaultButton>
			</Stack>
		</Stack>
    );
});

